import * as React from 'react';
import Helmet from 'react-helmet';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';


import { graphql } from 'gatsby';

import { Hero } from 'components/hero/Hero';
import { Container } from 'components/container/Container';
import { PdfViewer } from 'components/pdf-viewer/PdfViewer';
import { MainText } from 'components/main-text/MainText';
import { Button } from 'components/button/Button';

export const query = graphql`
  query GreinQuery($slug: String) {
    contentfulGrein (slug: { eq: $slug }) {
      title
      subtitle {
        subtitle
      }
      abstract {
        abstract
      }
      author {
        name
        id
      }
      richText {
        richText
      }
      articlePdf {
        file {
          url
        }
      }
    }
  }
`;

export default ({ data: { contentfulGrein : { title, subtitle, abstract, author, articlePdf, richText }} }: any) => {
  const fullContent = richText ? documentToHtmlString(JSON.parse(richText.richText)) : undefined;

  return (
  <>
    <Helmet title="Grein" />
    <Hero
      title={title}
      subtitle={subtitle ? subtitle.subtitle : undefined}
      contentHeading={author.length > 1 ? 'Höfundar:' : 'Höfundur:'}
      lines={author.map(({ name, id }: any) => ({ name, url: `/hofundur/${id}` }))}
    >
      {articlePdf && (
        <Button to={`https://${articlePdf.file.url}`} target="_blank">
          Sækja PDF
        </Button>
      )}

    </Hero>
    <Container>
      {abstract && (
        <p style={{ marginBottom: 50 }}>
          {abstract.abstract}
        </p>
      )}
      {fullContent && (<MainText html={fullContent} />)}
      {articlePdf && (
        <PdfViewer url={articlePdf.file.url} />
      )}
    </Container>
  </>
)};
