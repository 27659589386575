import * as React from 'react';

import s from './MainText.scss';

interface IMainTextProps {
  html: string;
}

export const MainText = ({ html }: IMainTextProps) => (
  <div className={s.mainText} dangerouslySetInnerHTML={{ __html: html}}/>
);
