import * as React from 'react';
import { Document, Page } from 'react-pdf';

import s from './PdfViewer.scss';

interface IPdfViewerProps {
  url: string;
}

export const PdfViewer = ({ url }: IPdfViewerProps) => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState()

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onloadSuccess = ({ numPages }: any) => setTotalPages(numPages);

  return (
    <div className={s.pdfViewer}>
      <div className={s.pdfViewer__content}>
        <Document file={url} onLoadSuccess={onloadSuccess} className={s.pdfViewer__document}>
          <Page height={1000} pageNumber={page} className={s.pdfViewer__page} />
        </Document>
      </div>
      <div className={s.pdfViewer__controls}>
        <button className={s.pdfViewer__next} onClick={nextPage}>&gt;</button>
        <button className={s.pdfViewer__prev} onClick={prevPage}>&lt;</button>
      </div>
      <div className={s.pdfViewer__pages}>
        <button onClick={prevPage}>&lt;</button>
        &nbsp;&nbsp; {page} / {totalPages} &nbsp;&nbsp;
        <button onClick={nextPage}>&gt;</button>
      </div>
    </div>
  );
};
